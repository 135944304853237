/*
 * @Date: 2022年8月5日
 * @descriotion:社保-补缴
 */
import { INSURANCE } from '../api'
import { METHOD, request } from '@/utils/request'

// 补缴列表
export async function getListApi(params) {
  return request(`${INSURANCE}/supplyPay/getList`, METHOD.GET, params)
}

// 获取险种的公司和员工比例
export async function getScaleApi(params) {
  return request(`${INSURANCE}/supplyPay/getScale`, METHOD.POST, params)
}

// 添加补缴调差
export async function addSupplyPayApi(params) {
  return request(`${INSURANCE}/supplyPay/addSupplyPay`, METHOD.POST, params)
}

// 删除补缴账单
export async function deleteSupplyPayApi(params) {
  return request(`${INSURANCE}/supplyPay/deleteSupplyPay`, METHOD.POST, params)
}

// 可补缴人员列表
export async function canSupplyPayPersonApi(params) {
  return request(`${INSURANCE}/supplyPay/canSupplyPayPerson`, METHOD.GET, params)
}

// 获取员工参保的险种
export async function getInsureTypeApi(params) {
  return request(`${INSURANCE}/supplyPay/getInsureType`, METHOD.GET, params)
}

// 判断账单是否存在
export async function getTenantBillApi(params) {
  return request(`${INSURANCE}/tenantBill/getTenantBill`, METHOD.GET, params)
}

// 下载批量补缴调差模板
export async function exportBatchSupplyPayTemplate(params) {
  return request(`${INSURANCE}/supplyPay/exportBatchSupplyPayTemplate`, METHOD.GET, params, { responseType: 'blob', headers: { isDownload: true }})
}

// 批量导入员工在缴参保方案
export async function batchImportSupplyPay(params) {
  return request(`${INSURANCE}/supplyPay/batchImportSupplyPay`, METHOD.POST, params)
}
